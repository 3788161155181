"use client";

import { signOut } from "next-auth/react";

import AuthForm from "@/components/auth/AuthForm";
import { useFavorites } from "@/contexts/FavoritesContext";

interface AuthFormWrapperProps {
  signOutParam: boolean;
}

const AuthFormWrapper = ({ signOutParam }: AuthFormWrapperProps) => {
  const { favorites, clearFavorites } = useFavorites();

  if (signOutParam) {
    signOut({ redirect: false });
  }

  return <AuthForm favorites={favorites} closeDialog={() => {}} clearFavorites={clearFavorites} isPage={true} />;
};

export default AuthFormWrapper;
